@import '../../../base/assets/scss/_base/config';

.ntk-banner{
  position: relative;
  text-align: left;
  margin-bottom: 44px;
  border-radius: 20px;
  overflow: hidden;
  &__img{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 74.86%;
    img{
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
    }
  }

  &__content{
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    top: 0;
    justify-content: end;
    padding: 0 20px 20px 25px;

    h1{
      font-style: normal;
      margin: 0;
      color: white;
      font-family: var(--font-family-heading);
      font-size: 24px;
      font-weight: 700;
      line-height: 1.15;
    }
  }
}

@media screen  and (min-width: map-get($breakpoints, xs)){
  .ntk-banner{
    &__img{
      padding-top: 72.28%;
    }
    &__content{
      padding: 0 30px 25px;
      h1{
        font-size: 40px;
      }
    }
  }
}

@media screen  and (min-width: map-get($breakpoints, s)){
  .ntk-banner{
    &__img{
      padding-top: 75%;
    }
    &__content {
      padding: 0 10px 30px 25px;
      h1{
        font-size: 36px;
      }
    }
  }
}

@media screen  and (min-width: map-get($breakpoints, m)){
  .ntk-banner{
    &__img{
      padding-top: 56.15%;
    }
    &__content {
      padding: 0 30px 40px;
      h1{
        font-size: 40px;
      }
    }
  }
}

@media screen  and (min-width: map-get($breakpoints, l)){
  .ntk-banner{
    &__img{
      padding-top: 56.2%;
    }
    &__content {
      padding: 0 50px 50px 30px;
    }
  }
}

@media screen  and (min-width: map-get($breakpoints, xl)){
  .ntk-banner{
    &__img{
      padding-top: 56.14%;
    }
  }
}

@media screen  and (min-width: map-get($breakpoints, xxl)){
  .ntk-banner{
    &__img{
      padding-top: 47.58%;
    }
  }
}
