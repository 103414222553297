@import "../../../base/assets/scss/_base/config";

.m-tv {

  &__container {
    max-width: 415px;
    max-height: 2968px;
    background: $white;
    position: relative;
    background-size: cover;
    height: 193px;

    .m-tv {


      &__vote {
        position: absolute;
        right: -22px;
        top: 40px;

        &--heading {
          width: 144px;
          height: 64px;
          flex-shrink: 0;
          color: $gotham-dark;
          font-family: var(--font-family-base);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.24px;
          text-align: left;
        }

        &--btn {
          width: 105px;
          flex-shrink: 0;
          border-radius: 200px;
          background: $turbo-vote-gradient;
          box-shadow: $fluffy-pancake-alt;
          position: absolute;
          right: 0;
          text-align: center;
          font-family: var(--font-family-heading);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 200% */
          text-decoration: none;
          height: 35px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 10px;
          margin-right: 40px;

          .btn-text {
            background: $turbo-vote-btn-gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}


@media screen and (min-width: map-get($breakpoints, s)) {
  .m-tv {

    &__container {
      height: 153px;
      .m-tv {

        &__vote {
          right: -22px;
          top: 25px;


        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, m)) {
    .m-tv {
      &__container {
        .m-tv {
          &__vote {
            &--heading {
              width: 140px;
            }

            &--btn {
              margin-top: 10px;
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}

// app specific css
.turbo_vote_app, .turbo_vote_app_dark {

  .m-tv {
    &__container {
      max-width: 312px;
      max-height: 148px;
      margin: 20px auto;

      .m-tv__vote {
        right: 0;
        top: 22px;
      }
    }
  }
}

.turbo_vote_app_dark  {
  .m-tv {
    &__container {
      .m-tv {
        &__vote {

          &--heading {
            color: $white;
          }

          &--btn {
            background: $rhino;
            box-shadow: $turbo-vote-dark;

            .btn-text {
              background: $turbo-vote-btn-gradient-dark;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

        }
      }
    }
  }
}

