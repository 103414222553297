@import '../../../base/assets/scss/_base/config';

$searchBoxShadow: 0px -1px 4px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.1);
$searchBorderRadius: 24px;
$searchInputHeight: 44px;
$searchInputHeightMWeb: 40px;
$verticalPadding: 20px;
$topWrapHeight: $searchInputHeight + ($verticalPadding * 2);
$searchBorderColor: $pebble;

.m-recipe-search {
  padding: 30px 0 33px;
  border-bottom: 1px solid $pebble;
  &__title {
    text-align: left;
    span {
      line-height: 1.2;
      font-size: 20px;
      font-weight: 700;
      font-family: var(--font-family-heading);
    }
  }
  .recipe__search-form {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 30px 0;
    display: flex;
  }

  &__boundary {
    position: relative;
    width: 100%;
    max-width: 600px;

    input[aria-expanded=true] {
      border-bottom-left-radius: 0;
      border-color: transparent;
      & ~ .m-recipe-search--suggestions:after {
        display: block;
      }
      & ~ .m-recipe-search--suggestions:before {
        height: 1px;
        background-color: $searchBorderColor;
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 8px;
        right: 8px;
      }
      & ~ button {
        border-bottom-right-radius: 0;
      }
      & ~ button,
      & ~ .m-recipe-search--suggestions ul {
        border-color: transparent;
      }
    }

    &:hover {
      .m-recipe-search--suggestions:after {
        display: block;
      }
      input,
      button,
      .m-recipe-search--suggestions ul {
        border-color: transparent;
      }
    }
  }

  .wafer-autocomplete-list{
    display: none;
    &.wafer-autocomplete-list-active{
      display: block;
    }
  }

  input {
    border: 1px solid $bob;
    border-radius: 0;
    flex: 1;
    float: left;
    outline: none;
    width: 100%;
    border-top-left-radius: $searchBorderRadius;
    border-bottom-left-radius: $searchBorderRadius;
    border-color: $searchBorderColor;
    border-right: none;
    padding: 0 0 0 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: $searchInputHeight;
    height: $searchInputHeight;
    color: $batcave;
    max-width: calc(100% - 61px);
    &::placeholder{
      color: $dolphin;
      padding-left: 5px;
      font-family: var(--font-family-heading);
      font-size: 14px;
      font-weight: 600;
    }
  }

  button {
    transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: $searchBorderRadius;
    border-bottom-right-radius: $searchBorderRadius;
    background-color: white;
    border:1px solid $searchBorderColor;
    border-left: none;
    padding: 10px 20px;
    height: $searchInputHeight;
    svg {
      fill: $dory;
      width: 20px;
      height: 20px;
    }
  }

  &--suggestions {
    background-color: $white;
    display: block;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;
    border-bottom-right-radius: $searchBorderRadius;
    border-bottom-left-radius: $searchBorderRadius;

    ul {
      border: 1px solid $bob;
      border-top: 0;
      list-style-type: none;
      margin: 0;
      padding: 8px 0 0;
      text-align: left;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-color: $searchBorderColor;

      &, li:last-of-type {
        border-bottom-right-radius: $searchBorderRadius;
        border-bottom-left-radius: $searchBorderRadius;
      }

      li {
        cursor: pointer;
        font-size: 14px;
        padding: 8px 20px;

        &:hover {
          background-color: $marshmallow;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        mark {
          background-color: transparent;
          font-weight: bold;
          pointer-events: none;
        }
        &:last-of-type {
          padding-bottom: 16px;
        }
      }
    }

    &:after {
      content: "";
      display: none;
      position: absolute;
      pointer-events: none;
      top: -$searchInputHeight;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: $searchBorderRadius;
      box-shadow: $searchBoxShadow;
    }

  }

  &__results {
    @media screen and (min-width: 901px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 28px 24px;
      margin-bottom: 28px;
    }
    &__item {
      display: flex;
      margin-bottom: 20px;
      @media screen and (min-width: 901px) {
        display: block;
        margin-bottom: 0;
      }
      &:hover &__title {
        color: $dory;
      }
      &__title {
        display: block;
        text-decoration: none;
        text-align: left;
        padding: 5px 0 0 20px;
        color: $batcave;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        font-family: var(--font-family-heading);
        @media screen and (min-width: 901px) {
          padding: 16px 4px 0;
        }
      }
      a:first-child {
        display: block;
        .m-recipe-search__results--item-img {
          width: 28.9vw;
          height: 28.9vw;
          background: #999;
          border-radius: 12px;
          overflow: hidden;
          @media screen and (min-width: 901px) {
            width: 100%;
            height: auto;
            aspect-ratio: 267/184;
          }
          img {
            z-index: 1;
            object-fit: cover;
            display: block;
            width: 100%;
            height: 100%;
            object-position: center center;
          }
        }
      }
    }
  }
  &__button {
    text-decoration: none;
    padding: 0 24px;
    margin-bottom: 8px;
    margin-top: 5px;
    display: inline-block;
    color: $accent_blue;
    line-height: 44px;
    font-size: 14px;
    font-family: var(--font-family-heading);
    background-color: transparent;
    border-radius: 48px;
    border: 1px solid $accent_blue;
    font-weight: 600;

    &:hover {
      background-color: $accent_blue;
      color: $white;
    }
  }
}

@media print {

  .m-recipe-search {
    display: none;
  }

}
