@import "../../../base/assets/scss/_base/config";
@mixin suggestionsBox {
  background-color: $white;
  left: 0;
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 101;
}

@mixin suggestionList {
  a {
    align-items: center;
    color: black;
    display: flex;
    padding: 8px 19px;
    text-decoration: none;
    width: 100%;
    justify-content: space-between;
  }

  strong {
    font-family: var(--font-family-base);
    font-weight: bold;
    pointer-events: none;
  }

  span {
    pointer-events: none;
    color: #979797;
    flex-grow: 1;
    font-size: 11px;
    text-align: right;
  }
}

.m-yfinance-markets {
  background-color: $white;
  font-size: 14px;
  position: relative;

  // INDICES
  &.indices {
    border: 1px solid $dirty_seagull;
    max-width: 100%;

    main {
      display: flex;

      .tabs-section {
        display: flex;
        flex-grow: 1;

        @media screen and (max-width: 970px) {
          flex-direction: column;
          width: 100%;
        }

        // first UL
        .tabs {
          align-self: flex-start;
          flex-direction: column;
          width: 87px;

          @media screen and (max-width: 970px) {
            background-color: $white-gray;
            flex-direction: row;
            width: 100%;
          }

          .tab {
            font-size: 12px;
            justify-content: flex-start;
            line-height: 1;
            padding: 10px;

            @media screen and (max-width: 970px) {
              flex-grow: 0;
              line-height: 1.5;
            }
          }
        }
        // end of first UL

        //start of second UL
        .details {
          margin: 10px 0 10px 10px;
          width: 100%;

          @media screen and (max-width: 970px) {
            margin-left: 0;
            width: 100%;
          }

          li {
            @media screen and (max-width: 970px) {
              position: relative;
            }

            .button {
              @media screen and (max-width: $screen-md) {
                display: block;
              }
            }

            .slides {
              margin-left: 40px;

              @media screen and (max-width: $screen-md) {
                align-items: center;
                display: flex;
                height: 38px;
              }

              .inner-tab {
                @media screen and (max-width: 970px) {
                  padding: 0 25px 0 0;
                }

                .symbol {
                  line-height: 25px;
                  width: 30%;

                  @media screen and (max-width: $screen-md) {
                    width: 27%;
                  }
                }

                .price {
                  display: flex;
                  display: inline-block;
                  flex: auto;
                  justify-content: center;
                  line-height: 25px;
                  padding-bottom: 0;
                  padding-top: 0;
                }

                .delta-price,
                .delta-percent {
                  display: flex;
                  flex: auto;
                  justify-content: center;
                  line-height: 25px;
                  padding-bottom: 0;
                  padding-top: 0;
                  width: 23%;
                }
              }
            }

            .next-btn {
              @media screen and (max-width: $screen-md) {
                display: block;
              }
            }
          }
        }
        //end of second UL
      }

      .open-wrapper {
        @media screen and (max-width: 970px) {
          display: block;
        }
      }

      //y-finance-quote-search
      .market-form-wrapper {
        align-self: center;
        margin: 0 20px;

        @media screen and (max-width: 970px) {
          display: none;
          left: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &.active {
          display: block;
        }

        form {
          input {
            @media screen and (max-width: 970px) {
              background-color: $white-gray;
              border-color: transparent;
              color: #989898;

              &::placeholder {
                color: black;
              }
            }
          }

          #removeCompany {
            @media screen and (max-width: 970px) {
              display: block;
            }
          }
        }
      }

      //footer
      footer {
        .footer {
          @media screen and (max-width: 970px) {
            text-align: center;
          }

          a {
            padding-left: 30px;
          }
        }
      }
    }
  }

  // NOT INDICES
  main {
    .tabs-section {
      // first UL
      .tabs {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;

        .tab {
          background-color: $white-gray;
          color: $a11y-dark-gray;
          display: flex;
          flex: auto;
          justify-content: center;
          line-height: 40px;
          padding: 0;
          position: relative;
          text-align: center;
          transition-delay: 0s;
          transition-duration: 0.5s;
          transition-timing-function: $ease;
          will-change: opacity;
          box-shadow:
            -1px 0px 0px 0px grey,
            1px 0px 0px 0px grey;

          &:hover {
            cursor: pointer;
          }

          &::after {
            color: $blue;
          }

          &.active {
            background-color: $white;
            color: black;
            opacity: 1;
          }

          a {
            color: #666;
            text-decoration: none;
            width: 100%;
          }
        }
      }
      // end of first UL

      // start of second UL
      .details {
        color: $white;
        margin-bottom: 5px;
        padding: 0;

        li {
          display: none;
          overflow: hidden;

          &.active {
            display: block;
          }

          &.US,
          &.Globals {
            .price {
              margin-right: 10px;
            }
          }

          &.Currencies {
            .price {
              margin-left: 10px;
            }
          }

          .button {
            background-color: $white-gray;
            border: 0;
            border-radius: 19px;
            color: $gray-darker;
            cursor: pointer;
            display: none;
            height: 38px;
            left: 0;
            margin-left: -19px;
            position: absolute;
            top: 0;
            width: 38px;
            z-index: 100;

            &:disabled {
              cursor: default;
              opacity: 0.2;
            }

            .icon {
              .left-icon {
                margin-left: 10px;
              }
            }
          }

          .slides {
            .inner-tab {
              display: flex;
              font-weight: 500;

              &.positive {
                .delta-price,
                .delta-percent {
                  color: #018051;
                }
              }

              &.negative {
                .delta-price,
                .delta-percent {
                  color: #cf363b;
                }
              }

              .symbol {
                line-height: 32px;
                padding: 0 5px;

                &.detailsTabSpan {
                  display: flex;
                  display: inline-block;
                  flex: auto;
                  font-size: 11px;
                  justify-content: center;
                  padding-bottom: 0;
                  padding-top: 0;
                  text-align: left;
                }

                &.detailsTabSymbol {
                  color: $gray-dark;
                  font-family: var(--font-family-base);
                  font-weight: bold;
                  text-transform: uppercase;
                  width: 27%;
                }

                a {
                  color: $gray-dark;
                  text-decoration: none;

                  &:hover {
                    color: $gray-light;
                  }
                }
              }

              .price {
                color: $a11y-dark-gray;
                line-height: 32px;
                padding-left: 10px;
                text-align: left;
                width: 23%;
              }

              .delta-price {
                display: flex;
                display: inline-block;
                flex: auto;
                justify-content: center;
                line-height: 32px;
                width: 20%;
              }

              .delta-percent {
                display: flex;
                display: inline-block;
                flex: auto;
                justify-content: center;
                line-height: 32px;
                padding: 0 10px 0 5px;
                text-align: right;
              }
            }
          }

          .next-btn {
            background-color: $white-gray;
            border: 0;
            border-radius: 19px;
            color: $gray-darker;
            cursor: pointer;
            display: none;
            height: 38px;
            margin-right: -19px;
            position: absolute;
            right: 0;
            top: 0;
            width: 38px;
            z-index: 100;

            &:disabled {
              cursor: default;
              opacity: 0.2;
            }

            .icon {
              display: inline;
              flex: auto;
              justify-content: center;
              line-height: 39px;
              padding-left: 0;
              padding-right: 10px;
              text-align: left;

              &::before {
                display: inline-block;
                height: 12px;
                width: 10.2333px;
              }

              .right-icon {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .open-wrapper {
      background-color: transparent;
      color: black;
      display: none;
      font-family: var(--font-family-base);
      font-weight: bold;
      height: 38px;
      line-height: 1;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 48px;

      &:hover {
        background-color: transparent;
      }

      .icon {
        height: 100%;
        left: 0;
        padding: 10px 16px 7.75px 15px;
        position: absolute;
        width: 100%;
      }
    }

    //Y finance quote search
    .market-form-wrapper {
      position: relative;

      form {
        display: flex;
        margin-bottom: 10px;
        position: relative;

        input {
          background-color: $white;
          border: 1px solid $gray-border;
          color: black;
          display: flex;
          flex: auto;
          font-family: var(--font-family-base);
          font-size: 14px;
          font-weight: normal;
          padding: 9px 58px 9px 10px;
          width: 100%;
        }

        // first a
        #removeCompany {
          background-color: transparent;
          cursor: pointer;
          display: inline-block;
          font-family: var(--font-family-base);
          font-weight: bold;
          height: 38px;
          line-height: 1;
          position: absolute;
          right: 48px;
          text-align: center;
          top: 0;
          width: 48px;

          span {
            height: 15.25px;
            position: absolute;
            right: auto;
            top: 12.4px;
            width: 10.2333px;

            &::before {
              display: inline-block;
              height: 12px;
              width: 100%;
            }
          }
        }

        // second a
        .search-wrapper {
          background-color: $blue;
          border: 0;
          display: inline-block;
          font-family: var(--font-family-base);
          font-weight: bold;
          height: 38px;
          line-height: 1;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          width: 48px;

          &:hover {
            background-color: $blue;
          }

          .searchIcon {
            height: 100%;
            padding: 10px 15px 7.75px 15.9333px;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }
      }

      #markets-suggestion-box .wafer-autocomplete-item * {
        pointer-events: none;
      }

      .suggestions-box {
        @include suggestionsBox;

        ul {
          border: 1px solid $blue;
          margin: 0;
          padding-left: 0;

          &:empty {
            display: none;
          }
        }

        li {
          font-family: var(--font-family-base);
          font-size: 13px;
          line-height: 1.25;
          list-style: none;

          &:hover {
            background-color: $blue;

            &,
            span {
              color: $white;
            }
          }
          @include suggestionList;
        }
      }
    }
  }

  //footer
  footer {
    flex-grow: 1;
    flex-shrink: 100%;
    padding: 0 10px;

    a {
      color: $blue;
      display: inline-block;
      font-size: 13px;
      margin: 5px auto 0;
      text-align: left;
      text-decoration: none;

      &:hover {
        color: black;
      }
    }

    p {
      border-top-color: $gray-border;
      border-top-style: solid;
      border-top-width: 1px;
      color: $a11y-gray-light;
      font-family: var(--font-family-base);
      font-size: 10px;
      font-weight: normal;
      line-height: 14px;
      margin: 10px 0;
      padding: 10px 0 0;
      text-align: left;
    }

    .footer {
      margin-bottom: 10px;
      text-align: right;

      a {
        color: $a11y-gray-light;
        font-family: var(--font-family-base);
        font-size: 10px;
        font-weight: normal;
        line-height: 1.4;

        @media screen and (max-width: 330px) {
          margin-left: 10%;
        }

        &:hover {
          color: $gray-dark;
        }

        img {
          @media screen and (max-width: 330px) {
            margin-left: 33%;
          }

          vertical-align: middle;
        }
      }
    }
  }

  span {
    font-size: 12px;
  }

  .tabs-section {
    .tabs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      justify-content: space-between;

      .new-tab {
        width: 92px;
        border: 1px solid $dirty_seagull;
        border-radius: 30px;
      }

      .new-tab.active {
        border: 1px solid $picasso;
        border-radius: 30px;
        width: 92px;
        line-height: 37px;
        a {
          color: $picasso;
        }
      }
    }

    .details {
      padding: 0 4px;
    }
  }

  //rename all classes
  //new design style starts from here
  .finance-market {
    box-shadow:
      0px 16px 20px 0px rgba(199, 205, 210, 0.4),
      0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 32px 20px 24px;
    .suggestions-box {
      @include suggestionsBox;
      border-radius: 0 0 20px 20px;
      overflow: hidden;

      ul {
        margin: 0;
        padding-left: 0;
        padding-top: 20px;
        border: 1px solid $dirty_seagull;
        border-radius: 0 0 20px 20px;

        &:empty {
          display: none;
        }
      }

      li {
        font-family: var(--font-family-base);
        font-size: 13px;
        line-height: 1.25;
        list-style: none;
        outline: none;
        &:hover {
          background-color: $marshmallow;
        }
        &:last-child {
          border-radius: 0 0 20px 20px;
        }
        @include suggestionList;
      }
    }


    &__title {
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      font-family: var(--font-family-heading);
      margin-top: 0;
      margin-bottom: 20px;
    }
    &__tabs-section {
      .tabs-option {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        gap: 12px;

        .new-tab {
          border: 1px solid $dirty_seagull;
          border-radius: 30px;
          width: 100%;
          height: 38px;
          padding: 0 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          a {
            width: 100%;
            line-height: 35px;
            height: 100%;
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            color: $batcave;
            text-decoration: none;
            font-family: var(--font-family-base);
          }

          &.active {
            border-color: $picasso;
            background-color: $alice_blue;

            a {
              color: $picasso;
              font-weight: 500;
            }
          }
        }
      }
      .details {
        color: $white;
        margin-bottom: 4px;
        padding: 0;

        li {
          display: none;
          overflow: hidden;

          &.active {
            display: block;
          }
          &.inactive {
            a{
              display: none;
            }
          }
          &.US,
          &.Globals {
            .price {
              margin-right: 10px;
            }
          }

          &.Currencies {
            .slides {
              margin: 22px 0;
              &.noTabs {
                margin-top: 14px;
              }
              .price {
                margin-left: 10px;
              }
            }
          }

          .button {
            background-color: $white-gray;
            border: 0;
            border-radius: 19px;
            color: $gray-darker;
            cursor: pointer;
            display: none;
            height: 38px;
            left: 0;
            margin-left: -19px;
            position: absolute;
            top: 0;
            width: 38px;
            z-index: 100;

            &:disabled {
              cursor: default;
              opacity: 0.2;
            }

            .icon {
              .left-icon {
                margin-left: 10px;
              }
            }
          }

          .slides {
            display: grid;
            // row-gap: 36px;
            margin: 28px 0px;

            &.noTabs {
              margin-top: 14px;
            }

            .inner-tab {
              display: flex;
              font-size: 13px;
              color: $batcave;
              font-family: var(--font-family-base);
              font-weight: 600;
              font-style: normal;
              line-height: 20px;
              text-transform: uppercase;
              &.positive {
                .delta-price,
                .delta-percent {
                  color: #018051;
                }
              }

              &.negative {
                .delta-price,
                .delta-percent {
                  color: #cf363b;
                }
              }

              .symbol {
                line-height: 32px;
                padding: 0 5px;

                &.detailsTabSpan {
                  display: flex;
                  flex: auto;
                  justify-content: flex-start;
                  padding-bottom: 0;
                  padding-top: 0;
                  text-align: left;
                  flex-direction: column;
                  width: 27%;

                  div.currency {
                    color: $batcave;
                    font-weight: 600;
                    font-style: normal;
                    line-height: 20px;
                  }

                  div.per {
                    font-family: var(--font-family-base);
                    color: $backwater;
                    line-height: 12px;
                    font-weight: 500;
                    font-size: 10px;
                  }
                }

                &.detailsTabSymbol {
                  a {
                    text-wrap: nowrap;
                    font-size: 13px;
                    color: $batcave;
                    font-family: var(--font-family-base);
                    font-weight: 600;
                    font-style: normal;
                    line-height: 20px;
                    text-transform: uppercase;
                  }
                }

                a {
                  color: $gray-dark;
                  text-decoration: none;

                  &:hover {
                    color: $gray-light;
                  }
                }
              }

              .price {
                color: $batcave;
                font-size: 13px;
                line-height: 20px;
                font-weight: 500;
                font-family: var(--font-family-base);
                font-style: normal;
                padding-left: 10px;
                text-align: left;
                width: 23%;
              }

              .delta-price,
              .delta-percent {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                display: flex;
                flex: auto;
                justify-content: center;
                line-height: 20px;
                width: 20%;
                font-family: var(--font-family-base);
              }

              // .delta-percent {
              //   display: flex;
              //   display: inline-block;
              //   flex: auto;
              //   justify-content: center;
              //   line-height: 20px;
              //   font-style: normal;
              //   font-weight: 400;
              //   padding: 0 10px 0 5px;
              //   text-align: right;
              // }
            }

            .inner-tab:nth-child(n+2) {
              margin-top: 36px;
            }

            .inner-tab.currencies:nth-child(n+2) {
              margin-top: 24px;
            }
          }

          .next-btn {
            background-color: $white-gray;
            border: 0;
            border-radius: 19px;
            color: $gray-darker;
            cursor: pointer;
            display: none;
            height: 38px;
            margin-right: -19px;
            position: absolute;
            right: 0;
            top: 0;
            width: 38px;
            z-index: 100;

            &:disabled {
              cursor: default;
              opacity: 0.2;
            }

            .icon {
              display: inline;
              flex: auto;
              justify-content: center;
              line-height: 39px;
              padding-left: 0;
              padding-right: 10px;
              text-align: left;

              &::before {
                display: inline-block;
                height: 12px;
                width: 10.2333px;
              }

              .right-icon {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    &__search {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: baseline;

      .market-search-box {
        width: 100%;
        flex: 1;
        form {
          width: 100%;
          position: relative;
          input {
            font-size: 12px;
            width: 100%;
            font-weight: 400;
            line-height: 14px;
            font-style: normal;
            font-family: var(--font-family-base);
            padding: 10px 20px;
            border-radius: 30px;
            border: 1px solid $dirty_seagull;
            outline: none;
          }
          .drop-down-suggestion {
            border-radius: 20px 20px 0 0;
          }
          .search-wrapper {
            border: none;
            background-color: transparent;
            position: absolute;
            top: 10px;
            right: 6px;
            .searchIcon {
              svg {
                fill: $picasso;
              }
            }
          }

          #removeCompany {
            border-radius: 50%;
            background-color: $alice_blue;
            cursor: pointer;
            display: none;
            font-family: var(--font-family-base);
            font-weight: bold;
            height: 24px;
            line-height: 1;
            position: absolute;
            right: 6px;
            text-align: center;
            top: 6px;
            width: 24px;

            span {
              position: absolute;
              left: 33%;
              top: 25%;
              width: 8px;

              &::before {
                display: inline-block;
                height: 12px;
                width: 100%;
              }
              svg {
                fill: $picasso;
              }
            }
          }
        }
      }
      .market-link {
        a {
          text-decoration: none;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          font-style: normal;
          text-wrap: nowrap;
          color: $backwater;
          font-family: var(--font-family-base);
        }
        .next-icon {
          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, l)) {
    .finance-market {
      .market-search-box {
        form {
          .search-wrapper{
            top: 12px;
          }
          input{
            padding: 12px 20px;
          }
        }
      }
      &__tabs-section {

        .details {
          li {
            .slides {
              .inner-tab {
                .price {
                  padding-left: 0;
                }
                .delta-percent {
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }

    }
  }

  @media screen and (min-width: map-get($breakpoints, xl)) {
    .finance-market {
      &__title {
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        font-family: var(--font-family-heading);
        margin-top: 0;
        margin-bottom: 20px;
      }
      &__tabs-section {
        .tabs-option {
          .new-tab,
          .new-tab.active {
            a{
              font-size: 11px;
              font-family: var(--font-family-base);
            }
          }
        }
        .details {
          li {
            .slides {
              margin: 40px 0;
              &.noTabs {
                margin-top: 14px;
              }
              .inner-tab {
                .symbol {
                  &.detailsTabSymbol {
                    font-size: 13px;
                    color: $batcave;
                    font-family: var(--font-family-heading);
                    font-weight: 700;
                    text-transform: uppercase;
                    font-style: normal;
                    line-height: 20px;
                  }
                }
              }
            }
          }

          li.Globals {
            .slides {
              margin: 36px 0;
              &.noTabs {
                margin-top: 14px;
              }
              .inner-tab {
                .detailsTabSpan {
                  a {
                    text-wrap: inherit;
                    padding-right: 12px;
                  }
                }
              }

              .inner-tab.globals:nth-child(n+2) {
                margin-top: 20px;
              }
            }
          }

          li.Currencies {
            .slides {
              margin: 34px 0;
              &.noTabs {
                margin-top: 14px;
              }
            }
          }
        }
      }

    }
  }

  @media screen and (min-width: map-get($breakpoints, xxl)) {
    .finance-market {
      padding-top: 24px;
      &__title {
        margin-bottom: 24px;
      }

      &__tabs-section {

        .details {
          li {
            .slides {

              .inner-tab {
                .price {
                  padding-left: 10px;
                }
                .delta-percent {
                  justify-content: center;
                }
              }
            }
          }
          li.Globals {
            .slides {
              margin: 40px 0;
              &.noTabs {
                margin-top: 14px;
              }
              .inner-tab {
                .detailsTabSpan {
                  a {
                    text-wrap: nowrap;
                    padding-right: 0
                  }
                }
              }
              .inner-tab.globals:nth-child(n+2) {
                margin-top: 36px;
              }
            }
          }
        }
      }


    }
  }
}
