/**
 * vim: set et sw=4 ts=4 ff=unix fileencoding=utf-8 ft=css :
 *
 * @fileoverview Shared styles for RecommendedForYou and ReadMore modules
 */
@charset 'utf-8';

@import '../_base/config';
@import '../_base/mixins';

/**
 * Gemini ad entry styles
 */
.has-gemini-ad > .module-wrap .ad-gemini-rm {
  > div {
    display: table;

    > div {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
  }

  .list-post-image {
    height: 95px;
    overflow: hidden;
    width: 132px;

    .list-post-link {
      display: block;
      width: 100%;
    }
  }

  .list-post-description {
    border-left: 12px solid transparent;
    display: table-cell;
    vertical-align: top;

    .list-post-title a {
      color: $batcave;
      text-decoration: none;
    }

    &:hover {
      .list-post-title a {
        color: $dory;
      }
    }

    .list-post-title a:hover {
      color: $dory;
    }

    .list-post-title {
      font-family: var(--font-family-base);
      font-size: 15px;
      font-weight: normal;
      line-height: 19px;
      margin-bottom: 0;
      margin-top: 0;
      transition: color $speed $ease;
    }
  }
}
