@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';

.m-video-wafer {
  &__player {
    &--container {
      height: 380px;
      .wafer-video-rendered {
        iframe {
          border-radius: 16px;
        }
      }
      .smartphone & {
        margin: 0 -10px;
      }

      &.mail-us {
        height: 459px;
      }
    }
  }

  &--grid{
    .m-video-wafer__player--container{
      .wafer-video-rendered {
        iframe {
          border-radius: 16px;
        }
      }
      .smartphone & {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &.unified_player_video,
  &.unified_player_video_show{
    background-color: $gray-base-light;
    color: white;
    font-family: var(--font-family-base);
    overflow: hidden;
    text-align: left;

    .m-video-wafer{
      &__wrapper{
        display: flex;
        margin: 0 auto;
        max-width: 1440px;
        padding: 25px;

        @include media-mobile-large {
          padding: 25px 0;
        }

        &--desc-open{
          .m-video-wafer {
            &__toggle {

              &--more {
                display: none;
              }

              &--less {
                display: block;
              }
            }
            &__description{
              &--text{
                @include media-mobile-large {
                  display: block;
                }
              }
            }
          }
        }

      }

      &__toggle {
        color: $blue;
        cursor: pointer;
        display: none;
        flex: 0 0 70px;
        font-size: 16px;
        text-align: right;

        @include media-mobile-large {
          display: block;
        }

        &--more{
          display: block;
        }
        &--less{
          display: none;
        }
      }
      &__content{
        width: 100%;
      }

      &__player{
        &--container {
          background-color: $gray-base;
          display: block;
          height: 36vw;
          z-index: 1;

          @include media-mobile-large {
            height: 56vw;

            .vp-main {
              width: 100% !important;
            }

            .vp-playlist-container {
              display: none;
            }
          }

          .vp-content .vp-main {
            background-color: $gray-base;
            transition: width, height 0.4s ease 0s;
          }
        }
      }

      &__description {
        display: block;
        overflow: hidden;
        padding: 15px 0;

        @include media-mobile-large {
          padding: 15px;
        }

        &--text{
          @include media-mobile-large {
            display: none;
          }
        }

        &--title{
          font-size: 24px;
          letter-spacing: 0;
          line-height: 1;
          margin: 0 0 15px;
          text-transform: none;

          @include media-mobile-large {
            font-size: 18px;
          }
        }

      }

      &__heading{
        display: flex;
        justify-content: space-between;

        &--title{
          font-size: 16px;
          font-weight: 400;
          margin: 0 0 20px;
          padding-right: 10px;
        }

      }

      &__share{
        display: flex;
        height: 40px;

        &--btn{
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          margin-right: 8px;
          width: 40px;
        }

        &--facebook{
          background-color: $social-facebook;
          color: white;
          font-size: 12px;
          font-weight: 700;
          text-decoration: none;
          width: 80px;

          &:hover {
            background-color: $social-facebook-hover;
          }

          span {
            height: 18px;
            padding-top: 2px;
          }
        }

        &--twitter{
          background-color: $social-twitter;

          &:hover {
            background-color: $social-twitter-hover;
          }
        }

        &--email{
          background-color: $social-email;

          &:hover {
            background-color: $social-email-hover;
          }
        }
      }

      &__svg{
        fill: white;
        height: 18px;
        width: 15px;
      }
    }


  }

  &.unified_player_video_show {
    .m-video-wafer{
      &__wrapper{
        padding: 25px 0;
      }

      &__description{
        padding: 20px;

      }

      &__heading {
        font-size: 22px;
        font-weight: 700;
      }
    }


  }



}
