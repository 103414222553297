@import '../../../base/assets/scss/_base/config';

.m-stream__pagination{
  display: flex;
  justify-content: center;
  &__link{
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 15px;
    display: block;
    text-decoration: none;
    color: $batcave;

    &:hover{
      background-color: $marshmallow;
    }

    &--wrapper{
      display: flex;
      margin: 0 3px;
      justify-content: center;
      align-items: center;

      // This is to show one less item after the first delimiter and before the last delimiter
      .m-stream__pagination__delimiter--left + &,
      &:has(+ .m-stream__pagination__delimiter--right)
      {
        display: none;
      }
    }

    &--active{
      .m-stream__pagination__link{
        background-color: $dory;
        color: white;

        &:hover{
          background-color: $dory-hover;
        }
      }
    }
  }

  &__next, &__prev{
    .m-stream__pagination__link{
      width: auto;
      padding: 0 ;
      border-radius: 5px;
      div{
        height: 100%;
      }
      span{
        display: none;
      }
      svg{
        padding: 0;
        height: 15px;
        width: auto;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .m-stream__pagination{
    &__link{
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 20px;

      &--wrapper{
        margin: 0 5px;
        .m-stream__pagination__delimiter--left + &,
        &:has(+ .m-stream__pagination__delimiter--right){
          display: none;
        }
      }

    }
    &__next, &__prev{
      .m-stream__pagination__link{
        padding: 0 5px;
        span{
          display: inline;
        }
        svg{
          padding: 0 10px;
        }
      }
    }

  }

}
