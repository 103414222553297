@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'yss_olympics';

@mixin yss_bottom_arrow_svg() {
  padding: 0px 42px 0px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: $picasso;
  font-family: var(--font-family-heading);
  white-space: nowrap;
  border: 1px solid $dirty_seagull;
  background: $white;
  border-radius: 24px;

  &:hover {
    background: $grey_hair;
  }
}

@mixin yss_bottom_arrow_svg_after_content() {
  content: url("data:image/svg+xml; utf8, <svg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.56532 5.69597L2.13298 1.19885C1.79113 0.852923 1.21931 0.847475 0.870302 1.20158C0.51772 1.55931 0.51951 2.13042 0.867618 2.48271L6.56532 8.26459L12.2648 2.48089C12.6067 2.13405 12.612 1.55387 12.2621 1.19976C11.9104 0.842934 11.3476 0.843843 10.9994 1.19704L6.56532 5.69597Z' fill='rgba(0, 71, 255, 1)'></path></svg>");
  position: absolute;
  top: 11px;
  right: 20px;
  transform: rotate(0deg);
}

@mixin yss_dropdown() {
  position: absolute;
  right: 0;
  text-align: left;
  z-index: 15;

  span {
    @include yss_bottom_arrow_svg();

    &::after {
      @include yss_bottom_arrow_svg_after_content();
    }

    &.open {
      border: 1px solid $picasso;
      background: $grey_hair;
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &--list {
    background: $white;
    position: relative;
    text-transform: uppercase;
    min-width: 136px;
    right: 3px;

    &_label.disabled {
      color: $dirty_seagull;
      background: rgba(224, 228, 233, 0.08);
      cursor: none;
    }

    &_label.m-yss__vs-olympics {
      text-transform: capitalize;
    }

    .visible {
      display: block;
    }

    .inVisible {
      display: none;
    }

    &_main {
      margin: 0px 10px;
    }

    ul {
      background-color: $white;
      border: none;
      display: none;
      margin: 5px 0px;
      text-align: initial;
      padding: 5px 0px;
      border-radius: 8px;
      box-shadow: $light-elevation;

      li {
        list-style: none;
        padding: 0;
        line-height: 40px;
        position: relative;

        .m-yss__vs__dropdown--list_main,
        .m-yss_h_menu_with_glide__dropdown--list_main,
        .m-yss_h_menu__dropdown--list_main {
          &:hover {
            background-color: $marshmallow;
            border-radius: 4px;

            label {
              color: $picasso;
            }

            .m-yss__vs__dropdown--list,
            .m-yss_h_menu_with_glide__dropdown--list,
            .m-yss_h_menu__dropdown--list {
              &_off_season {
                color: $picasso;
              }
            }
          }
        }

        label {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          gap: 5px;
          padding: 0 0 0 10px;
          font-size: 12px;
          font-weight: 600;
          color: $batcave;
          font-family: var(--font-family-heading);
          &.selected {
            color: $picasso;
            .m-yss__vs__dropdown--list,
            .m-yss_h_menu_with_glide__dropdown--list,
            .m-yss_h_menu__dropdown--list {
              &_off_season {
                color: $picasso;
              }
            }
          }
        }
      }
    }

    &_off_season {
      font-size: 8px;
      font-weight: 600;
      color: $squirrel;
    }
  }
}

.m-yahoo-sports-scores {
  border: 0;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-base);
  margin: 0 0 30px;
  padding: 0 15px;
  position: relative;
  width: 100%;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  .scores-header {
    margin-bottom: 20px;
    width: 100%;

    @include media-mobile-smaller {
      margin-bottom: 0;
    }

    .header-title {
      color: $gray-dark;
      float: left;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
      margin: 0;
      padding: 8px 3px 0 0;
      vertical-align: middle;

      &.mobile {
        display: none;
        font-size: 14px;
        padding: 4px 3px 4px 0;

        @media screen and (max-width: 550px) {
          display: block;
        }
      }

      @media screen and (max-width: 550px) {
        display: none;
      }
    }

    .attribution-logo {
      display: block;
      float: left;
      height: 22px;
      margin: 8px 15px 3.5px 0;

      @media screen and (max-width: 550px) {
        margin-top: 3.5px;
      }
    }

    .scores-menu-wrapper {
      display: table;

      .scores-menu {
        display: table-row;
        width: 100%;
        z-index: 10;

        li {
          background: lighten($white-gray, 2%);
          color: $grey-light;
          cursor: pointer;
          display: table-cell;
          font-family: inherit;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.2px;
          line-height: 36px;
          text-align: center;
          text-transform: uppercase;

          &:hover,
          &.selected {
            background: $grey-light;
            color: white;
          }

          .scores-title {
            padding: 0 25px;

            @media screen and (max-width: 480px) {
              padding: 0 5px;
            }
          }
        }
      }

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }

  .scores-outer {
    position: relative;

    .scores-container {
      border-bottom: 1px solid $gray-border;
      border-top: 1px solid $gray-border;
      height: 87px;
      position: relative;
      width: 100%;

      .scores-wrap {
        height: 100%;
        position: relative;
        overflow: initial;

        .score-slide {
          display: table;
          float: left;
          height: 100%;
          min-height: 85px;
          white-space: nowrap;

          &.sport-title-wrap {
            background-color: $white;
            width: 66px;

            .sport-title {
              background-color: lighten($white-gray, 2%);
              color: $grey-light;
              display: table-cell;
              font-size: 13px;
              font-weight: bold;
              height: 85px;
              letter-spacing: 1.2px;
              text-align: center;
              text-transform: uppercase;
              vertical-align: middle;
            }
          }

          &.score-wrap {
            border-right: 1px solid $gray-border;
            min-width: 125px;

            .score {
              display: table-cell;
              font-size: 12px;
              line-height: 12px;
              padding: 10px;
              text-align: left;
              vertical-align: middle;

              .game-state {
                color: $gray-dark;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;
              }

              .away-team,
              .home-team {
                background-position: center left;
                background-repeat: no-repeat;
                background-size: 20px;
                color: $grey-light;
                font-weight: normal;
                padding-top: 12px;

                &::after {
                  clear: both;
                  content: '';
                  display: block;
                  height: 0;
                  visibility: hidden;
                }

                &.winner {
                  color: $gray-dark;
                  font-weight: bold;
                }
              }

              .abbrev,
              .logo {
                height: 16px;
                margin: -4px 5px 0 0;
                width: 16px;
              }

              .abbrev {
                text-transform: uppercase;
              }

              .score-val {
                float: right;
              }

              .logo {
                display: inline-block;
                height: 16px;
                margin: -4px 5px 0 0;
                width: 16px;
              }
            }
          }
        }
      }

      .glide__arrows {
        .glide__arrow {
          border: 0;
          box-shadow: none;
          color: $blue;
          cursor: pointer;
          font-size: 30px;
          height: 85px;
          line-height: 90px;
          padding: 1px 8px;
          position: absolute;
          top: 0;
          transform: none;
          vertical-align: middle;
          z-index: 5;

          &.glide__arrow--left {
            background: $white;
            left: 0;
          }

          &.glide__arrow--right {
            background: $white;
            right: 0;
          }
        }
      }
    }
  }
}

.m-yss__vs {
  text-align: left;
  border-radius: 20px;
  width: 100%;
  box-shadow: $fluffy-pancake;
  height: 100%;

  &--container {
    padding: 16px 20px 0px;
    height: 100%;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
  }

  &--main {
    padding: 20px 0px 0px;
    min-height: 360px;

    &-data {
      border: 0;
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    &-list {
      display: flex;
      flex-direction: row;
      padding: 0px 0px 28px;
      min-height: 64px;

      &.inVisible {
        display: none;
      }

      &-left {
        display: flex;
        flex-direction: column;
        width: 70%;
        min-width: 70%;
        border-right: 1px solid $dirty_seagull;
        padding-right: 20px;
        @media all and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)){
          padding-right: 8px;
        }

        .m-yss {

          &__away-team,
          &__home-team {
            font-size: 13px;
            line-height: 13px;
            font-family: var(--font-family-heading);
            font-weight: 600;
            letter-spacing: -0.3px;
            color: $backwater;
            padding: 0px 5px 0px 0px;

            &.winner {
              color: $batcave;

              .m-yss__winner {
                background-color: $picasso;
              }
            }

            &.inprogress {
              color: $batcave;
            }

            .m-yss {
              &__winner {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-left: 8px;
                @media all and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)){
                  margin-left: 4px;
                }
              }

              &__logo {
                height: 24px;
                margin: -6px 16px 8px 0;
                width: 24px;
              }

              &__score-val {
                float: right;
                display: flex;
                align-items: center;
                font-size: 13px;
              }

              &__abbrev {
                display: inline-block;
              }
              &__abbrev_alt {
                display: none;
                text-transform: uppercase;
              }

              &__seed {
                margin-left: 4px;
                font-size: 10px;
                color: $batcave;
                font-family: var(--font-family-heading);
                font-weight: 400;
                line-height: 12px;
                word-wrap: break-word;
              }

              @media all and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)){
                &__abbrev_alt {
                  display: inline-block;
                }
                &__abbrev {
                  display: none;
                }
              }
            }

            &.game-status-not-final {
              &_at, &_ht {
                color: $batcave;
                .m-yss__score-val {
                  font-size: 11px;
                  font-weight: 400;
                  font-family: var(--font-family-base);
                }
              }
            }
          }

          &__away-team {
            margin-top: 5.5px;
            .m-yss {
              &__abbrev,
              &__abbrev_alt,
              &__score-val {
                margin-bottom: 20px;
              }
            }
          }
          &__home-team {
            .m-yss {
              &__abbrev,
              &__abbrev_alt,
              &__score-val {
                margin-bottom: 0px;
                top: -2px;
                position: relative;
              }
              &__logo {
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 24px;
        font-size: 10px;
        line-height: 16px;
        font-family: var(--font-family-heading);
        font-weight: 700;
        @media all and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)){
          padding-left: 16px;
        }

        .game {
          &-broadcast_channel {
            color: $dolphin;
            font-weight: 500;
            text-transform: uppercase;
          }

          &-status-not-final {
            color: $batcave;
            font-weight: 500;
          }

          &-status-final {
            font-size: 10px;
            font-weight: 700;
          }

          &-broadcast_channel, &-status-not-final {
            font-size: 11px;
            line-height: 16px;
            font-family: var(--font-family-base);

            &.inprogress {
              font-family: var(--font-family-heading);
              font-weight: 600;
              color: $batcave;
            }
          }

        }
      }
    }
  }

  &--footer {
    display: none;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 12px;
    font-family: var(--font-family-base);
    font-weight: 500;
    color: $backwater;
    position: relative;
    top: -8px;
    &.show {
      display: flex;
    }

    &.invisible {
      display: none;
    }
    &_main {
      display: flex;
      span {
        display: block;
        padding-bottom: 12px;
      }
      svg path {
        fill: $backwater;
      }
    }
  }

  &--link {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $picasso;
      svg path {
        fill: $picasso;
      }
    }
  }

  &--select_tabs {
    position: relative;
  }

  &__dropdown {
    @include yss_dropdown();

    span {
      padding-right: 48px;
      max-width: 105px;
      position: relative;
      right: -30px;
      &::after {
        right: 15px;
      }
      &.has-m-yss__vs-olympic {
        right: -15px;
        max-width: 120px;
        @media screen and (min-width: 1281px) and (max-width: 1360px) {
          right: -30px;
        }
      }
    }
  }

  &--title {
    font-size: 14px;
    font-family: var(--font-family-heading);
    font-weight: 700;
    color: $batcave;
    margin: 12px 0px 0px;
    line-height: 14px;
  }

  .league--fallback {
    background: $marshmallow;
    padding: 10px 18px;
    display: none;
    flex-direction: row;
    gap: 16px;
    font-family: var(--font-family-heading);
    color: $pigeon;
    align-items: center;
    border-radius: 12px;

    &.league_active {
      display: flex;
    }

    &--logo {
      width: 55px;
      height: 50px;
    }

    &--title {
      margin: 0px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }
  }

  &-mlb {
    display: flex;
    flex-direction: row;
    margin-left: -8px;

    &-info {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      line-height: 10px;
      font-family: var(--font-family-heading);
      padding-left: 10px;
      text-transform: capitalize;

      &-display-name {
        font-weight: 600;
      }

      &-out {
        font-weight: 500;
        color: #6e7780;
      }
    }
  }
}

.m-yss__h {
  .m-yahoo-sports-scores {
    padding: 0px;
    margin-bottom: 0px;
  }

  text-align: left;
  margin-bottom: 40px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  &-scores-container {
    border-radius: 12px;
    position: relative;
    width: 100%;
    box-shadow: 0px 4px 8px 0px #0000001A, 0px 0px 1px 0px #0000001A;
    display: flex;
    flex-direction: row;

    .scores-wrap {
      height: 100%;
      position: relative;
      overflow: initial;

      .score-slide {
        display: table;
        float: left;
        height: 100%;
        min-height: 114px;
        white-space: nowrap;

        &.score-fallback {
          display: flex;
          align-items: center;
          padding: 10px;
          border-right: 1px solid $dirty_seagull;
          @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
            padding: 0px 10px;
          }
        }

        &.sport-title-wrap {
          background-color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 12px;

          &.olympic-title-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $gotham;
            font-size: 12px;
            font-family: var(--font-family-heading);
            font-weight: 700;
            line-height: 14px;

            &.inVisible {
              display: none;
            }
          }

          &.first {
            border-left: 0px;
          }

          .sport-title {
            color: $black;
            font-size: 14px;
            font-weight: 800;
            line-height: 12px;
            letter-spacing: 0px;
            text-transform: uppercase;
            font-family: var(--font-family-heading);
            &.m-yss_h-olympics {
              text-transform: capitalize;
              font-size: 12px;
            }
          }
        }

        &.score-wrap {
          min-width: 162px;
          max-width: fit-content;
          &.m-yss__h-score-strip{
            min-width: 106px;
          }

          &.first {
            background: linear-gradient(90deg,rgba(0,0,0,0.03) 2.4%, transparent 10.32%);
            &.enableGradient {
              background: linear-gradient(90deg,rgba(0,0,0,0.03) 2.4%, transparent 10.32%);
            }
            &.disableGradient {
              background: none;
            }
          }
          &.last {
            border-right: 1px solid $dirty_seagull;
          }

          .score {
            display: table-cell;
            font-size: 14px;
            line-height: 12px;
            font-family: var(--font-family-heading);
            position: relative;
            top: 14px;
            padding: 0px;
            text-align: left;
            border-right: 0px;

            .game {
              &-scores-container {
                padding: 6px 18px 0px;
                border-right: 1px solid $dirty_seagull;
                margin-bottom: 14px;

                &.last {
                  @media screen and (min-width: map-get($breakpoints, xs)) {
                    border: 0;
                  }
                }
              }

              &-state {
                color: $batcave;
                font-size: 10px;
                letter-spacing: 0;
                line-height: 0px;
                min-height: 7px;
                font-family: var(--font-family-base);
                display: flex;
                justify-content: space-between;
                margin-bottom: 18px;
              }

              &-broadcast_channel {
                color: $dolphin;
                font-weight: 400;
                text-transform: uppercase;
                svg {
                  margin-top: -10px;
                }
              }

              &-status-not-final {
                color: $dolphin;
                font-weight: 500;
              }

              &-status-final {
                font-weight: 600;
                font-size: 10px;
                text-transform: uppercase;
              }
            }

            .away-team,
            .home-team {
              background-position: center left;
              background-repeat: no-repeat;
              background-size: 20px;
              color: $backwater;
              font-weight: 700;
              font-size: 13px;
              line-height: 10px;

              &::after {
                clear: both;
                content: '';
                display: block;
                height: 0;
                visibility: hidden;
              }

              &.game-status-not-final {
                &_at, &_ht{
                  color: $batcave;
                  .score-val {
                    font-size: 11px;
                    font-weight: 400;
                  }
                }
              }

              &.winner {
                color: $batcave;
                font-weight: 700;
              }

              &.inprogress {
                color: $batcave;
              }
            }

            .abbrev {
              text-transform: uppercase;
              margin: 0px;
              display: inline-block;
            }

            .score-val {
              float: right;
              right: 0px;
            }

            .abbrev,
            .seed,
            .score-val {
              top: 2px;
              left:-1px;
              position: relative;
            }

            .seed {
              margin-left: 4px;
              font-size: 10px;
              color: $batcave;
              font-family: var(--font-family-heading);
              font-weight: 400;
              line-height: 12px;
              word-wrap: break-word;
            }

            .logo {
              display: inline-block;
              height: 24px;
              margin: -4.5px 18px 0 0;
              width: 24px;
            }

            .away-team {
              padding-bottom: 0px;
              padding-top: 0px;
              span.abbrev, span.score-val {
                margin-bottom: 24px;
              }
              .logo {
                margin-bottom: 16px;
              }
            }
          }

          .olympic-team {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 27px 0 28px;
            margin: 20px 0px;
            border-right: 1px solid $dirty_seagull;
            height: 74px;

            &-info, &-medals {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              width: 50px;
            }

            .logo {
              width: 24px;
              height: 24px;
              margin-bottom: 3px;
            }
            .abbrev {
              color: $batcave;
              font-size: 12px;
              font-family: var(--font-family-heading);
              font-weight: 600;
              line-height: 12px;
              word-wrap: break-word;
            }
            .score-val {
              color: $batcave;
              font-size: 14px;
              font-family: var(--font-family-heading);
              font-weight: 700;
              line-height: 16px;
              word-wrap: break-word;
            }

            &.last {
              border-right: 0;
            }

            &.view-all {
              justify-content: center;
              align-items: center;
              padding: 0 12px 0 13px;
            }

            &-view-all {
              border-radius: 48px;
              border: 1px solid $owl;
              display: inline-flex;
              height: 36px;
              padding: 10px 16px;
              align-items: center;
              gap: 8px;
              flex-shrink: 0;
              font-family: var(--font-family-heading);
              color: $picasso;
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
            }
          }
        }

        &.inVisible {
          display: none;
        }
      }
    }

    .league--fallback {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: var(--font-family-heading);
      color: $black;
      width: 142px !important;
      min-width: 142px !important;
      background: $marshmallow;
      border-radius: 12px;

      &--logo {
        width: 55px;
        height: 50px;
        margin: 5px 0px;
      }

      &--title, &--desc {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }
      &--desc {
        margin-bottom: 5px;
      }
    }
  }

  .glide {
    left: 0px;
    max-width: 100%;
    display: flex;
    flex-direction: row;

    &__track {
      width: calc(100% - 166px);
    }

    &_space {
      width: 10px;
      background: linear-gradient(90deg, transparent 12.4%, rgba(0, 0, 0, .03) 70.68%);
      position: relative;
      right: 10px;
    }

    &__arrows {
      width: 50px;
      border-radius: 0px 12px 12px 0px;
      background: $white;
      z-index: 10;
      display: flex;
      flex-direction: column;
      position: relative;
      right: 10px;

      .glide__arrow {
        button {
          padding: 0px;
        }
        &--right,
        &--left {
          border: 0;
          box-shadow: none;
          color: $picasso;
          cursor: pointer;
          font-size: 14px;
          background-color: $white;
          width: 100%;
          height: 100%;
          padding-left: 0px;
          padding-right: 0px;

          svg {
            left: 5px;
            position: relative;
            &.enable_arrow path {
              stroke: #0047ff;
              stroke-width: 2;
              stroke-linecap: round;
            }

            &.disable_arrow path {
              stroke: #C7CDD2;
              stroke-width: 2;
              stroke-linecap: round;
            }
          }
        }

        &--right {
          padding-top: 25px;
          padding-bottom: 13px;
          border-radius: 0px 12px 0px 0px;
        }
        &--left {
          padding-top: 18px;
          padding-bottom: 20px;
          border-radius: 0px 0px 12px 0px;
        }
      }
    }
  }

  .m-yss_h_menu_with_glide {
    border-right: 1px solid $dirty_seagull;
    display: none !important;

    @media screen and (min-width: map-get($breakpoints, xs)) {
      display: flex !important;
    }

    &--select_tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
    }

    &__dropdown {
      text-align: center;
      z-index: 15;

      span {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #fff;
        color: $gotham;
        font-size: 11px;
        font-family: var(--font-family-heading);
        font-weight: 700;
        text-transform: uppercase;
        line-height: 40px;
        word-wrap: break-word;
        width: 106px;
        padding: 0px 14px 0px 11px;

        &::after {
          content: url("data:image/svg+xml; utf8, <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.50072 4.85402L1.40933 0.834231C1.09378 0.525017 0.565942 0.520147 0.243786 0.836666C-0.0816752 1.15643 -0.080023 1.66692 0.241308 1.98181L5.50072 7.14999L10.7618 1.98019C11.0773 1.67016 11.0823 1.15156 10.7593 0.835042C10.4347 0.516089 9.9151 0.5169 9.59376 0.832607L5.50072 4.85402Z' fill='rgba(18, 22, 25, 1)'></path></svg>");
          position: relative;
          top: 1px;
          left: 3.7px;
        }

        &.open {
          border: 1px solid $picasso;
          background: $grey_hair;

          &::after {
            transform: rotate(180deg);
          }
        }
      }

      &--list {
        background: $white;
        position: absolute;
        top: 0px;
        text-transform: uppercase;
        min-width:136px;
        border-radius: 8px;

        &_label.disabled {
          color: $dirty_seagull;
          background: rgba(224, 228, 233, 0.08);
          cursor: none;
        }

        .visible {
          display: block;
        }

        .inVisible {
          display: none;
        }

        &_main {
          margin: 0px 10px;
        }

        ul {
          background-color: $white;
          border: none;
          display: none;
          margin: 0px;
          text-align: initial;
          padding: 5px 0px;
          border-radius: 8px;
          box-shadow: $light-elevation;

          li {
            list-style: none;
            padding: 0;
            line-height: 40px;
            position: relative;

            .m-yss__vs__dropdown--list_main,
            .m-yss_h_menu_with_glide__dropdown--list_main,
            .m-yss_h_menu__dropdown--list_main {
              &:hover {
                background-color: $marshmallow;
                border-radius: 4px;

                label {
                  color: $picasso;
                }

                .m-yss__vs__dropdown--list,
                .m-yss_h_menu_with_glide__dropdown--list,
                .m-yss_h_menu__dropdown--list {
                  &_off_season {
                    color: $picasso;
                  }
                }
              }
            }

            label {
              cursor: pointer;
              padding: 0 0 0 10px;
              font-size: 12px;
              font-weight: 700;
              color: $batcave;
              font-family: var(--font-family-heading);
              display: flex;
              flex-direction: row;
              gap: 5px;

              &.selected {
                color: $picasso;
                .m-yss__vs__dropdown--list,
                .m-yss_h_menu_with_glide__dropdown--list,
                .m-yss_h_menu__dropdown--list {
                  &_off_season {
                    color: $picasso;
                  }
                }
              }
            }
          }
        }

        span {
          text-transform: capitalize;
        }

        &_off_season {
          font-size: 8px;
          font-weight: 600;
          color: $squirrel;
        }
      }
    }
  }

  @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
    margin-bottom: 8px;

    &-scores-container {
      border-radius: 0px;
      box-shadow: none;
      background-color: none;

      .scores-wrap {
        padding-bottom: 9px;

        .score-slide {
          min-height: 88px;

          &.sport-title-wrap {
            display: none;
          }

          &.score-wrap {
            &.first {
              background: none;
              &.enableGradient, &.disableGradient {
                background: none;
              }
            }
            &.last {
              border-right: 0px;
            }

            .score {
              top: 0px;
              padding: 8px 0px 0px;
              border-right: 1px solid $dirty_seagull;

              .game {
                &-scores-container {
                  &.first {
                    padding: 0px 20px 0px 0px;
                  }
                  border-right: 0px;
                  padding: 0px 18px 0px 16px;
                  margin-bottom: 12px;
                }

                &-state {
                  margin-bottom: 12px;
                  min-height: 3.5px;
                }
              }

              .abbrev,
              .score-val {
                top: 5px;
                position: relative;
              }

              .logo {
                margin: -3px 16px 0 0;
              }

              .away-team {
                span.abbrev,
                span.score-val {
                  margin-bottom: 28px;
                }
                .logo {
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
      }

      .league--fallback {
        justify-content: flex-start;
      }
    }

    .glide {
      left: 0px;

      &__track {
        width: 100%;
      }

      &_space,
      &__arrows {
        display: none;
      }
    }
  }
}

.m-yss_h_menu {
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
    margin-bottom: 16px !important;
  }

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__title {
      text-align: left;
      font-size: 32px;
      line-height: 24px;
      letter-spacing: -1px;
      color: $batcave;
      text-transform: capitalize;
      font-family: var(--font-family-heading);

      &.cell {
        padding: 0;
      }
    }

    @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
      &__title {
        font-size: 28px;
      }
    }
  }

  &--select_tabs {
    position: relative;
    top: -20px;

    @media screen and (min-width: map-get($breakpoints, xs)) {
      display: none;
    }
  }

  &__dropdown {
    @include yss_dropdown();

    &--list {
      span {
        text-transform: capitalize;
      }
      &_label.m-yss_h-olympics {
        text-transform: capitalize;
      }
    }
  }
}
