@import "../../../base/assets/scss/_base/config";

// heading
@mixin heading-style-l {
  font-size: 18px;
  line-height: 36px;
}

@mixin heading-style-s {
  font-size: 18px;
  line-height: 36px;
}

@mixin heading-style-xs {
  font-size: 18px;
  line-height: 24px;
}
//heading ends

// sign image
@mixin sign-style-xxl {
  height: 210px;
  width: 210px;
  @at-root .m-hs .m-hs__image--cell{
    max-width: 210px;
  }
}

@mixin sign-style-xl {
  height: 230px;
  width: 230px;
  @at-root .m-hs .m-hs__image--cell{
    max-width: 230px;
  }
}

@mixin sign-style-m {
  height: 200px;
  width: 200px;
  @at-root .m-hs .m-hs__image--cell{
    max-width: 200px;
  }
}

@mixin sign-style-s {
  height: 220px;
  width: 220px;
  margin-top: auto;
  margin-bottom: auto;
  @at-root .m-hs .m-hs__image--cell{
    max-width: 220px;
  }
}

@mixin sign-style-xs {
  height: 125px;
  width: 125px;
  @at-root .m-hs .m-hs__image--cell{
    max-width: 125px;
  }
}
// sign image ends

//description and read more text

@mixin description-readmore-style-m {
  font-size: 16px;
  line-height: 28px;
}

@mixin description-readmore-style-xs {
  font-size: 14px;
  line-height: 24px;
}

//description and read more text ends

.m-horoscopes {
  font-family: var(--font-family-base);

  &.horoscopes_v2 {
    .m-hs {
      .m-hs__body {
        padding-bottom: 0;

        .m-hs__content {
          .m-hs__link {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            height: 100%;

            .m-hs__image {
              width: 178px;
              height: 178px;
              flex-shrink: 0;
              @at-root .m-hs .m-hs__image--cell{
                max-width: 178px;
              }
            }
          }
          .m-hs__heading {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 12px;
          }

          .m-hs__description {
            color: $black;
            font-size: 14px;
            font-style: normal;
            font-family: var(--font-family-base);
            font-weight: 400;
            line-height: 28px;
          }

          .m-hs__readmore {
            &--link {
              color: $picasso;
              font-size: 14px;
              font-style: normal;
              font-family: var(--font-family-base);
              font-weight: 400;
              line-height: 28px;
              text-decoration: none;
            }
          }

          .m-cb__cta--container {
            margin-top: 28px;
            justify-content: flex-start;

            a.m-cb__cta {
              text-wrap: nowrap;
              // safari
              white-space: nowrap;
            }
          }
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, xs)) {
      .m-hs {
        .m-hs__body {
          .m-hs__content {
            .m-hs__link {
              justify-content: center;
              .m-hs__image {
                @include sign-style-xs;
              }
            }
            .m-hs__heading {
              @include heading-style-xs;
            }

            .m-hs__description {
              @include description-readmore-style-xs;
            }

            .m-hs__readmore {
              &--link {
                @include description-readmore-style-xs;
              }
            }

            .m-cb__cta--container {
              justify-content: flex-end;
              a.m-cb__cta {
                padding: 11px 20px;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, s)) {
      .m-hs {
        .m-hs__body {
          .m-hs__content {
            .m-hs__link {
              .m-hs__image {
                @include sign-style-s;
              }
            }
            .m-hs__heading {
              @include heading-style-s;
            }
          }
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, m)) {
      .m-hs {
        .m-hs__body {
          .m-hs__content {
            .m-hs__link {
              justify-content: start;
              .m-hs__image {
                @include sign-style-m;
              }
            }

            .m-cb__cta--container {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, l)) {
      .m-hs {
        .m-hs__body {
          .m-hs__content {
            .m-hs__link {
              .m-hs__image {
                width: 210px;
                height: 210px
              }
            }
            .m-hs__heading {
              @include heading-style-l;
            }
          }
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, xl)) {
      .m-hs {
        .m-hs__body {
          .m-hs__content {
            .m-hs__link {
              .m-hs__image {
                @include sign-style-xl;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, xxl)) {
      .m-hs {
        .m-hs__body {
          .m-hs__content {
            .m-hs__link {
              .m-hs__image {
                @include sign-style-xxl;
              }
            }

            .m-cb__cta--container {
              a.m-cb__cta {
                padding: 13px 20px;
              }
            }
          }
        }
      }
    }


    &--grid {
      &.horoscopes_daily {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
