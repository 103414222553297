.m-travel-deals{
  margin-top: 20px;
  padding: 20px 0;
  border: 1px solid #e3e3e3;
  border-left: none;
  border-right: none;

  &__heading{
    margin-top: 0;
    margin-bottom: 20px;
    text-align: left;
  }

  iframe {
    height: 380px;
    
    @media screen and (min-width: 1265px) and (max-width: 1306px) {
      height: 530px;
    }

    @media screen and (max-width: 1165px) {
      height: 530px;
    }

    @media screen and (max-width: 802px) {
      height: 680px;
    }

    @media screen and (max-width: 753px) {
      height: 530px;
    }

    @media screen and (max-width: 480px) {
      height: 680px;
    }

    @media screen and (max-width: 421px) {
      height: 700px;
    }

    @media screen and (max-width: 393px) {
      height: 800px;
    }

    @media screen and (max-width: 370px) {
      height: 830px;
    }

    @media screen and (max-width: 365px) {
      height: 950px;
    }

    @media screen and (max-width: 338px) {
      height: 830px;
    }
  }
}
