@import '../../../base/assets/scss/_base/config';

.m-nc {
  text-align: left;
  font-family: var(--font-family-heading);
  color: $batcave;
  font-size: 16px;
  margin-bottom: 60px;

  // These styles apply before the carousel is initialized
  &__carousel:not(.glide--slider){
    .m-nc {
      &__list{
        gap: 80px;
      }
      &__slide {
        flex: 1 0 11%;
      }
    }
    @media screen and (max-width: 1128px){
      .m-nc {
        &__list {
          gap: 40px;
        }
        &__slide {
          flex-basis: 13%;
        }
      }
    }
    @media screen and (max-width: 900px){
      .m-nc {
        &__slide {
          flex-basis: 15%;
        }
      }
    }
    @media screen and (max-width: 650px){
      .m-nc {
        &__slide {
          flex-basis: 19%;
        }
      }
    }
    @media screen and (max-width: 500px){
      .m-nc {
        &__list {
          gap: 20px;
        }
        &__slide {
          flex-basis: 25%;
        }
      }
    }
  }

  &__slide{
    padding: 5px;
  }
  &__title {
    margin: 0 0 32px;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
  }

  &__link {
    background-color: $marshmallow;
    border-radius: 100%;
    overflow: hidden;
    display: block;
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;

    &:hover {
      color: $picasso;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__image {
    position: relative;
    height: 39%;
    width: 39%;
    max-width: 56px;
    max-height: 56px;
    overflow: hidden;
    margin-bottom: 8px;
    background-color: $marshmallow;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      position: absolute;
      top:0;
    }
  }

  &__text {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__controls {

    [disabled='true'] {
      display: none;
    }

    &--prev, &--next {
      position: absolute;
      top: 0;
      height: 100%;
      border: none;
      background-color: transparent;
      width: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .m-nc__controls--container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        height: 80px;
        width: 100%;
        padding: 12px;

      }

      &:hover {
        color: $dory;
      }

      svg {
        fill: $gotham;
      }
    }

    &--next {
      right: 0;

      .m-nc__controls--container {
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
      }
    }

    &--prev {
      left: 0;

      .m-nc__controls--container {
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px;
      }
    }

  }

  @media screen and (max-width:  map-get($breakpoints, s) -  1px) {
    padding-right: 0!important;

    .m-nc__controls {
      display: none;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)) {
  .m-nc__text{
    font-size: 13px;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .m-nc__text{
    font-size: 14px;
  }
  .m-nc__image{
    width: 45%;
    height: 45%;
  }
}


// nav carousel simple
.m-ncs {
  text-align: left;
  font-family: var(--font-family-heading);
  color: $black;
  margin-bottom: 60px;

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 80% */
  }

  &__pills {
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    gap: 10px;
  }

  &__pill {
    white-space: nowrap;
    border-radius: 48px;
    border: 1px solid $koala;
    background: $honeydew;
    cursor: pointer;

    &:hover {
      border: 1px solid $solitude;
      background: $solitude;

      .m-ncs__text {
        color: $picasso;
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__text {
    color: $pigeon;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    margin: 0;
    padding: 16px;

    &.shopping {
      padding: 18px 36px;
    }
  }
}


@media screen and (min-width: map-get($breakpoints, s)) {

  .m-ncs {

    &__title {
      margin-bottom: 40px;
    }

    &__pills {
      gap: 20px;
    }
    &__text {
      padding: 18px;
      font-size: 14px;
    }
  }

}

