@import "../../../base/assets/scss/_base/config";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";


.m-finance-banner {
  text-align: left;
  font-size: 12px;
  line-height: 1.167;
  color: $charcoal;
  display: flex;
  flex-direction: row;
  padding: 0 15px;

  &__list{
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;

    li{
      border-right: 1px solid $dirty_seagull ;
      margin-right: 16px;
    }

  }

  &__market{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 9px 0;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    &--positive {
      .m-finance-banner {
        &__market {
          &--change {
            color: $bonsai;
          }
        }

        &__icon {
          background-color: $bonsai;
        }
      }
    }

    &--negative {
      .m-finance-banner {
        &__market {
          &--change {
            color: $solo_cup;
          }
        }

        &__icon {
          background-color: $solo_cup;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &--title {
      font-weight: 600;
      margin: 0 0 7px 0;
    }

    &--price, &--change {
      margin: 0;
    }

    &--price {
      padding-right: 10px;
    }

    &--change {
      font-weight: 600;
    }

    &--container {
      display: flex;
    }
  }

  &__controls{
    display: flex;
    align-items: center;
    padding-left: 12px;
    &--prev, &--next{
      height: 15px;
      border: none;
      outline: none;
      background: none;
      padding: 5px;
      box-sizing: content-box !important;

      &[disabled='true']{
        svg{
          fill: $pebble;
        }
      }

      svg {
        height: 15px;
        fill: $batcave;
      }
    }
  }

  &__icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 6px;
    margin: 0 24px;
    flex-shrink: 0;

    &--small{
      height: 18px;
      width: 18px;
      padding: 3px;
      margin: 0 10px;
      display: none;
    }
    &--large{
      display: block;
    }

    svg {
      fill: $white;
    }
  }

  @media screen and (max-width: 754px) {
    .m-finance-banner {
      &__controls {
        display: none;
        button{
          display: none;
        }
      }

      &__market {
        &--price {
          margin-bottom: 5px;
        }

        & > div{
          width: 100%;
        }
        &--container{
          flex-direction: column;

          & > div{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      &__icon {
        &--small{
          display: block;
        }
        &--large{
          display: none;
        }
      }
    }
  }
}

.m-finance-banner.v2 {
  padding: 0;
  .m-finance-banner {

    &__list {
      li:first-of-type {
        margin-left: 20px;
      }
    }

    &__market {
      padding: 0;
      & > div{
        width: 50%;
      }

      &--title {
        color: $batcave;
        font-weight: 700;
        line-height: 13px;
        font-size: 11px;
        margin: 2px 0 0 0;
        height: 16px;
      }
      &--price {
        margin-bottom: 8px;
        margin-top: 5px;
        color: $batcave;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px;
      }
      &--right--container {
        text-align: right;
        padding: 0 13px 0px 0;
        p {
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
        }
        &--price {
          color: $batcave;
        }
        &--positive {
          color: $green;
        }
        &--negative{
          color: $red;
        }
      }

      &--positive, &--negative  {
        .m-finance-banner {
          &__market {
            &--change {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
            }
          }

          &__icon {
            height: 26px;
            width: 26px;
            margin: 10px 0 0 0;
            display: inline-block;
            box-shadow: 0.2px 0.5px 0.5px 0.2px $owl;
            background-color: $white;
            &--negative {
              fill: $red
            }
            &--positive {
              fill: $green;
            }
          }
        }
      }
    }

  }
}

@media screen and (min-width: map-get($breakpoints, xs)){
  .m-finance-banner.v2{
    .m-finance-banner {

      &__controls {
        display: flex;

        button {
          display: block;
        }

        position: absolute;
        right: 0;
        top: -44px;
        flex-direction: row;
      }
    }
  }
}
@media screen and (min-width: map-get($breakpoints, s)) {
  .m-finance-banner.v2 {
    .m-finance-banner {
      &__list {
        li:first-of-type {
          margin-left: 0;
        }
      }

      &__controls {
        top: -49px;
      }
      &__market {

        &--title {
          color: $batcave;
          font-weight: 700;
          font-size: 11px;
          line-height: 14px;
          margin-top: 2px;
        }

        &--container {
          flex-direction: column;
        }

        &--price {
          margin-bottom: 12px;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
        }

        &--positive, &--negative  {
          .m-finance-banner {
            &__market {
              &--change {
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
              }
            }

            &__icon {
              height: 26px;
              width: 26px;
            }
          }
        }
      }
    }
  }
}
