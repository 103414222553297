@import '../../../base/assets/scss/_base/config';

.m-aol-sebanner {
  margin-bottom: 40px!important;
  &.elections, &.news, &.politics {
    margin-bottom: 13px !important;
    @media screen and (min-width: map-get($breakpoints, s)) {
      margin-bottom: 29px!important;
    }
    @media screen and (min-width: 1100px) {
      margin-bottom: 44px!important;
    }
  }
  &.news, &.politics {
    margin-top: 6px;
    @media screen and (min-width: 480px) {
      margin-top: 14px!important;
    }
  }

  &--stream_wrapper {
    margin-top: -24px;
  }

  &--bluesky_wrapper {
    margin-top: -4px;

    @media screen and (min-width: map-get($breakpoints, l)) {
      margin-top: -14px;
    }
  }

  &__container {
    position: relative;
    text-align: center;
    width: 100%;
  }

  &__content_left,
  &__content_right {
    color: #fff;
    font-family: var(--font-family-heading);
    font-weight: 900;
    text-transform: uppercase;
    word-wrap: break-word;
    display: flex;
    text-align: left;

    &_text_1 {
      display: none;
    }

    &_text_2 {
      display: block;
    }
  }

  &__content_left {
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);

    &_text_1 {
      letter-spacing: -1px;
    }

    &_text_2 {
      line-height: 16px;
      letter-spacing: -0.5px;
    }

    @media screen and (min-width: 1124px) {
      font-size: 28px;
      line-height: 20px;
    }

    @media screen and (min-width: 1124px) {
      &_text_1 {
        display: block;
      }

      &_text_2 {
        display: none;
      }
    }
  }

  &__content_right {
    font-size: 12px;
    line-height: 10px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

    &_text {
      margin-right: 5px;
    }

    &_text_1 {
      position: relative;
      top: 2px;
    }

    &_icon {
      display: flex;
      align-items: center;
    }

    @media screen and (min-width: 600px) {
      font-size: 13px;
      line-height: 12px;
    }

    @media screen and (min-width: 540px) {
      &_text_1 {
        display: block;
      }

      &_text_2 {
        display: none;
      }
    }

    @media screen and (min-width: 900px) {
      &_text_1 {
        display: none;
      }

      &_text_2 {
        display: block;
      }
    }

    @media screen and (min-width: 1124px) {
      &_text_1 {
        display: block;
      }

      &_text_2 {
        display: none;
      }
    }
  }

  &__image {
    width: 100%;
    min-height: 52px;
    border-radius: 28px;
    &_static {
      max-height: 52px;
      height: 52px;
    }
  }

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: #fff;
    }
  }
}
.prime_day, .prime_day_app {
  .m-aol-sebanner {
    &__content_right {
      font-size: 12px;
      line-height: 10px;
      right: 20px;

      &_text {
        margin-right: 5px;
      }

      &_text_1 {
        display: none;
      }

      &_text_2 {
        display: block;
        line-height: 12px;
      }

      @media screen and (min-width: 1124px) {
        font-size: 13px;
        line-height: 12px;
      }
    }
    &__content_left {
      font-size: 13px;
      line-height: 13px;
      left: 17px;
      position: absolute;

      &.mWebTitle {
        display: none;
      }
      @media screen and (min-width: 600px) {
        font-size: 20px;
        left: 16%;
      }
      @media screen and (min-width: 750px) {
        font-size: 20px;
        left: 13%;
      }
      @media screen and (min-width: 900px) {
        font-size: 18px;
        left: 30px
      }
      @media screen and (min-width: 1280px) {
        font-size: 24px;
        left: 14%;
      }
      @media screen and (min-width: 1441px) {
        font-size: 24px;
        left: 12.5%;
      }

      &_text_2 {
        line-height: 13px;
        letter-spacing: -0.75px;
        @media screen and (min-width: 600px) {
          line-height: 15px;
        }
      }
      @media screen and (min-width: 600px) {
        &_text_1 {
          display: block;
        }

        &_text_2 {
          display: none;
        }
      }

      @media screen and (min-width: 900px) {
        &_text_1 {
          display: none;
        }

        &_text_2 {
          display: block;
        }
      }
      @media screen and (min-width: 1280px) {
        &_text_1 {
          display: block;
        }

        &_text_2 {
          display: none;
        }
      }
    }

    &__container {
      .m-aol-sebanner {
        &__logo {
          display: none;
          margin-left: 23px;
          float: left;
          position: relative;
          transform: translateY(-170%);
          @media screen and (min-width: 600px) {
            display: block;
          }
          @media screen and (min-width: 900px) {
            display: none;
          }
          @media screen and (min-width: 1280px) {
            display: block;
          }
        }
        &__aol_logo {
          display: flex;
          width: 42px;
          height: 16px;
          position: absolute;
          top: 30%;
          right: 27%;
          @media screen and (min-width: 450px) {
            right: 21.5%;
          }
          @media screen and (min-width: 600px) {
            right: 15.5%;
          }
          @media screen and (min-width: 540px) {
            right: 18%;
          }
          @media screen and (min-width: 750px) {
            right: 12.2%;
          }
          @media screen and (min-width: 900px) {
            right: 18.5%;
          }
          @media screen and (min-width: 1124px) {
            right: 16%;
          }
          @media screen and (min-width: 1280px) {
            right: 15%;
          }
          @media screen and (min-width: 1441px) {
            right: 13%;
            width: 51px;
            height: 20px;
          }
        }
      }
    }
  }
}

.paris_games {
  &.m-aol-sebanner {
    &.sports.smartphone {
      margin-bottom: 21px !important;
      margin-top: 42px;
    }
  }
}

.paris_games, .paris_games_medals_app {
  .m-aol-sebanner {
    &__image {
      border-radius: 100px;
    }

    &__content_right {
      font-size: 12px;
      line-height: 12px;

      @media screen and (min-width: map-get($breakpoints, xs)) {
        font-size: 14px;
        line-height: 14px;
      }

      &_text {
        margin-right: -8px;
        @media screen and (max-width: 390px) {
          margin-right: -16px;
        }
      }

      @media screen and (max-width: 1480px) {
        &_text_1 {
          display: none;
        }

        &_text_2 {
          display: block;
        }
      }

      @media screen and (max-width: 340px) {
        font-size: 11px;
      }
    }

    &__content_left {
      font-size: 24px;
      line-height: 26px;
      font-weight: 300;
      letter-spacing: 10px;
      background: linear-gradient(90deg, #FEE690 9.49%, #FFF8E0 49.38%, #FEE690 89.28%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      left: 6%;
      @media screen and (min-width: map-get($breakpoints, xxl)) {
        background: linear-gradient(90deg, #FFD640 9.82%, #FFF 53.95%, #A68200 107.56%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 1480px) {
        font-size: 32px;
        line-height: 56px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 260px;
      }

      &.mail-us {
        left: 6%;

        @media screen and (min-width: map-get($breakpoints, l)) {
          font-size: 32px;
          line-height: 56px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          min-width: 260px;
        }
      }

      &_text_2 {
        line-height: 28px;
        letter-spacing: 10px;

        @media screen and (min-width: map-get($breakpoints, xl)) {
          line-height: 36px;
          letter-spacing: 16px;
        }
        @media screen and (max-width: 540px) {
          letter-spacing: 5px;
        }

        @media screen and (max-width: 360px) {
          letter-spacing: 4px;
          font-size: 18px;
        }
      }

      @media screen and (min-width: map-get($breakpoints, xs)) {
        &_text_1 {
          display: none;
        }

        &_text_2 {
          display: block;
        }
      }
    }
  }

  &.linkTextDisabled {
    .m-aol-sebanner {
      &__image {
        min-height: 52px;
        border-radius: 100px;

        @media screen and (min-width: map-get($breakpoints, xs)) {
          min-height: 56px;
        }
      }

      &__content_right {
        display: none;
      }

      &__link {
        pointer-events: none;
      }

      &__content_left {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 260px;

        &_text_2 {
          display: none;
        }

        &_text_1 {
          display: block;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 10px;

          @media screen and (min-width: map-get($breakpoints, s)) {
            font-size: 28px;
            line-height: 28px;
            letter-spacing: 15px;
          }

          @media screen and (min-width: map-get($breakpoints, l)) {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 20px;
          }

          @media screen and (min-width: map-get($breakpoints, m)) {
            font-size: 28px;
          }

          @media screen and (min-width: map-get($breakpoints, xxl)) {
            font-size: 40px;
            line-height: 46px;
          }
        }
      }
    }
  }
}

.elections_2024, .elections_2024_app {
  .m-aol-sebanner {
    &__content_right {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.42px;
      line-height: 12px;
      text-transform: none;
      top: 50%;
      right: 12px!important;
      @media screen and (min-width: map-get($breakpoints, s)) {
        right: 36px!important;
        line-height: 28px;
      }

      &_text {
        margin-right: 12px;
        @media screen and (min-width: map-get($breakpoints, s)) {
          margin-right: 0px;
        }
      }

      &_text_1 {
        display: none;
      }
      &_text_2 {
        display: block;
      }
      @media screen and (min-width: map-get($breakpoints, s)) {
        &_text_1 {
          display: block;
        }
        &_text_2 {
          display: none;
        }
      }

    }
    &__content_left {
      font-size: 28px;
      line-height: 28px;
      left: 25px;
      position: absolute;
      text-transform: capitalize;

      @media screen and (min-width: map-get($breakpoints, s)) {
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        left: 36px;
      }

      &_text_2 {
        letter-spacing: -1.12px;
        @media screen and (min-width: map-get($breakpoints, s)) {
          letter-spacing: -1.36px;
        }
      }
    }
  }
  &.linkTextDisabled {
    .m-aol-sebanner {
      &__image {
        min-height: 52px;
        border-radius: 100px;
      }

      &__content_right {
        display: none;
      }

      &__link {
        pointer-events: none;
      }

      &__content_left {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 205px;
        @media screen and (min-width: 480px) {
          min-width: 235px;
        }
        @media screen and (min-width: map-get($breakpoints, xs)) {
          min-width: 260px;
        }
        @media screen and (min-width: map-get($breakpoints, s)) {
          min-width: 275px;
        }
        &_text_2 {
          display: none;
        }

        &_text_1 {
          display: block;
          font-size: 30px;
          letter-spacing: -1.2px;
          font-weight: 800;
          line-height: 28px;


          @media screen and (min-width: 480px) {
            font-size: 34px;
          }
          @media screen and (min-width: 540px) {
            font-size: 34px;
          }
          @media screen and (min-width: map-get($breakpoints, xs)) {
            font-size: 38px;
            line-height: 28px;
            letter-spacing: -1.52px;
          }
          @media screen and (min-width: map-get($breakpoints, s)) {
            font-size: 40px;
          }
        }
      }
    }
  }
}
