@import '../../../base/assets/scss/_base/config';

.featured-video {
  background-color: $marshmallow;
  display: table;
  font-family: var(--font-family-base);
  width: 100%;

  .image-container {
    display: table-cell;
    height: 242px;
    padding: 15px 20px 15px 15px;
    vertical-align: middle;
    width: 57%;

    @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
      display: table-header-group;
      width: 100%;
    }

    @media (min-width: 601px) and (max-width: 752px) {
      width: 50%;
    }

    @media (min-width: 900px) and (max-width: 1230px) {
      width: 50%;
    }

    a {
      color: $white;
      display: block;
      height: 242px;
      text-decoration: none;

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        height: 272px;
      }

      div {
        height: 100%;
        position: relative;
        width: 100%;

        .featured-video-image {
          background-position-x: center;
          background-position-y: center;
          background-size: cover;
          height: 100%;
          width: 100%;
        }

        .play-overlay {
          background: $featured-video-background;
          bottom: 0;
          filter: opacity(0.5);
          height: 100%;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          transition-delay: 0s;
          transition-duration: 0.2s;
          transition-property: opacity;
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          width: 100%;
          z-index: 2;
        }

        .play-button {
          bottom: 6px;
          filter: opacity(1);
          font-size: 12px;
          font-weight: bold;
          height: auto;
          left: 10px;
          letter-spacing: 1px;
          line-height: 25px;
          position: absolute;
          transition-delay: 0s;
          transition-duration: 0.2s;
          transition-property: opacity;
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          width: auto;
          z-index: 2;

          .icon::before {
            content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'%3e%3cpath d='M32.2%2c0l447.6%2c256L32.2%2c512V0z' fill-rule='evenodd'/%3e%3c/svg%3e");
            display: inline-block;
            margin-right: 3px;
            width: 11px;
          }
        }
      }
    }
  }

  .article-copy {
    display: table-cell;
    padding-left: 10px;
    padding-right: 20px;
    position: relative;
    text-align: left;
    vertical-align: middle;
    width: 43%;
    z-index: 2;

    &.video-right {
      padding-left: 20px;
      padding-right: 10px;
    }

    @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
      display: table-header-group;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    @media (min-width: 601px) and (max-width: 753px) {
      width: 50%;
    }

    @media (min-width: 900px) and (max-width: 1230px) {
      width: 50%;
    }

    .featured-video-channel {
      font-family: var(--font-family-base);
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 0;
      color: $dolphin;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;

      span {
        color: $batcave;
      }

      a {
        cursor: pointer;
        color: $dory;

        &:hover{
          color: $cobalt;
        }

      }

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 20px;
      }
    }

    h3 {
      color: $batcave;
      font-family: var(--font-family-base);
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      margin: 0;

      &.has-source {
        @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
          margin-top: 20px;
        }
      }

      &.no-source {
        @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .body {
      margin-bottom: 10px;
      margin-top: 5px;

      p {
        color: $batcave;
        font-family: var(--font-family-base);
        font-size: 15px;
        font-weight: normal;
        line-height: 1.267;
        margin: 0;
      }

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .cta {
      a {
        display: block;
        font-weight: bold;
        text-decoration: none;

        div {
          color: $white;
          display: inline-block;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.2px;
          line-height: 12px;
          margin: 8px 0;
          padding: 15px 30px 12px;
          text-align: center;
          text-transform: uppercase;
          top: 15px;

          background-color: $dory;


          &:hover {
            background-color: $dory-hover;
          }

        }
      }

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .tile-additional {
      font-family: var(--font-family-base);
      font-size: 14px;
      font-weight: normal;
      line-height: 1.286;
      color: $dory;

      &:hover {
        color: $dory-hover;
      }

      span {
        display: inline-block;
      }

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
